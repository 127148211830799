import "./Mentionslegales.scss";
import React from "react";
import { Container, Card } from "react-bootstrap";

class Mentionslegales extends React.Component {
  render() {
    return (
      <div className="App">
        <h1 className="titre-page">Mentions légales</h1>
        <Container className="container-mentions">
          <Container className="sous-sontainer-mentions">
            <Card className="card-mentions-side">
              <Card.Header className="card-header-mentions">
                Données personnelles
              </Card.Header>
              <Card.Text className="card-mentions-text">
                En France, les données personnelles sont notamment protégées par
                la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août
                2004, l'article L. 226-13 du Code pénal et la Directive
                Européenne du 24 octobre 1995. À l'occasion de l'utilisation du
                site www.code-couturier.com, peuvent êtres recueillies : l'URL
                des liens par l'intermédiaire desquels l'utilisateur a accédé au
                site www.code-couturier.com, le fournisseur d'accès de
                l'utilisateur, l'adresse de protocole Internet (IP) de
                l'utilisateur. En tout état de cause Alphonse Couturier ne
                collecte des informations personnelles relatives à l'utilisateur
                que pour le besoin de certains services proposés par le site
                www.code-couturier.com. L'utilisateur fournit ces informations
                en toute connaissance de cause, notamment lorsqu'il procède par
                lui-même à leur saisie. Il est alors précisé à l'utilisateur du
                site www.code-couturier.com l'obligation ou non de fournir ces
                informations. Conformément aux dispositions des articles 38 et
                suivants de la loi 78-17 du 6 janvier 1978 relative à
                l'informatique, aux fichiers et aux libertés, tout utilisateur
                dispose d'un droit d'accès, de rectification et d'opposition aux
                données personnelles le concernant, en effectuant sa demande
                écrite et signée, accompagnée d'une copie du titre d'identité
                avec signature du titulaire de la pièce, en précisant l'adresse
                à laquelle la réponse doit être envoyée. Aucune information
                personnelle de l'utilisateur du site www.code-couturier.com
                n'est publiée à l'insu de l'utilisateur, échangée, transférée,
                cédée ou vendue sur un support quelconque à des tiers. Seule
                l'hypothèse du rachat de Alphonse Couturier et de ses droits
                permettrait la transmission des dites informations à l'éventuel
                acquéreur qui serait à son tour tenu de la même obligation de
                conservation . et de modification des données vis à vis de
                l'utilisateur du site www.code-couturier.com. Le site n'est pas
                déclaré à la CNIL car il ne recueille pas d'informations
                personnelles. Les bases de données sont protégées par les
                dispositions de la loi du 1er juillet 1998 transposant la
                directive 96/9 du 11 mars 1996 relative à la protection
                juridique des bases de données.
              </Card.Text>
            </Card>
            <Card className="card-mentions-side">
              <Card.Header className="card-header-mentions">
                Limitations
              </Card.Header>
              <Card.Text className="card-mentions-text">
                Le site Internet ne pourra être tenu responsable de dommages
                matériels liés à l'utilisation du site. De plus, l'utilisateur
                du site s'engage à accéder au site en utilisant un matériel
                récent, ne contenant pas de virus et avec un navigateur de
                dernière génération mis-à-jour.
              </Card.Text>
            </Card>
          </Container>
          <Container className="sous-sontainer-mentions">
            <Card className="card-présentation-site">
              <p>
                Alphonse Couturier
                <br />
                SIREN : 903 761 096
              </p>
              <p>
                Hébergeur : 1&1 IONOS, 7 place de la gare, 57200 Sarreguemines{" "}
              </p>
              <p>
                Web design: Alphonse Couturier <br />
                Développement web: Alphonse Couturier <br />
                Référencement naturel: Alphonse Couturier
              </p>
            </Card>
            <Card className="card-mentions-center">
              <Card.Header className="card-header-mentions">
                Conditions d'utilisation
              </Card.Header>
              <Card.Text className="card-mentions-text">
                L'utilisation du site www.code.couturier.com implique
                l'acceptation pleine et entière des conditions générales
                d'utilisation ci-après décrites. Ces conditions d'utilisation
                sont susceptibles d'être modifiées ou complétées à tout moment,
                les utilisateurs du site www.code.couturier.com sont donc
                invités à les consulter de manière régulière. Ce site est
                normalement accessible à tout moment aux utilisateurs. Une
                interruption pour raison de maintenance technique peut être
                toutefois décidée par www.code.couturier.com, qui s'efforcera
                alors de communiquer préalablement aux utilisateurs les dates et
                heures de l'intervention. Le site www.code.couturier.com est mis
                à jour régulièrement par Alphonse Couturier. De la même façon,
                les mentions légales peuvent être modifiées à tout moment :
                elles s'imposent néanmoins à l'utilisateur qui est invité à s'y
                référer le plus souvent possible afin d'en prendre connaissance.
              </Card.Text>
            </Card>
          </Container>
          <Container className="sous-sontainer-mentions">
            <Card className="card-mentions-side">
              <Card.Header className="card-header-mentions">
                Description des services
              </Card.Header>
              <Card.Text className="card-mentions-text">
                Le site www.code.couturier.com a pour objet de fournir une
                information concernant l'ensemble des activités de la société.
                Alphonse Couturier s'éfforce de fournir sur le site
                www.code.couturier.com des informations aussi précises que
                possible. Toutefois, il ne pourra être tenu responsable des
                omissions, des inexactitudes et des carences dans la mise à
                jour, qu'elles soient de son fait ou du fait des tiers
                partenaires qui lui fournissent ces informations. Tous les
                informations indiquées sur le site www.code.couturier.com sont
                données à titre indicatif, et sont susceptibles d'évoluer. Par
                ailleurs, les renseignements figurant sur le site
                www.code.couturier.com ne sont pas exhaustifs. Ils sont donnés
                sous réserve de modifications ayant été apportées depuis leur
                mise en ligne.
              </Card.Text>
            </Card>
            <Card className="card-mentions-side">
              <Card.Header className="card-header-mentions">
                Propriété du site
              </Card.Header>
              <Card.Text className="card-mentions-text">
                Alphonse Couturier est propriétaire des droits de propriété
                intellectuelle ou détient les droits d'usage sur tous les
                éléments accessibles sur le site, notamment les textes, images,
                graphismes, logo, icônes, sons, logiciels. Toute reproduction,
                représentation, modification, publication, adaptation de tout ou
                partie des éléments du site, quel que soit le moyen ou le
                procédé utilisé, est interdite, sauf autorisation écrite
                préalable de : Alphonse Couturier. Toute exploitation non
                autorisée du site ou de l'un quelconque des éléments qu'il
                contient sera considérée comme constitutive d'une contrefaçon et
                poursuivie conformément aux dispositions des articles L.335-2 et
                suivants du Code de Propriété Intellectuelle.
              </Card.Text>
            </Card>

            <Card className="card-mentions-side">
              <Card.Header className="card-header-mentions">
                Liens hypertextes et cookies
              </Card.Header>
              <Card.Text className="card-mentions-text">
                Le site www.code-couturier.com contient un certain nombre de
                liens hypertextes vers d'autres sites, mis en place avec
                l'autorisation de Alphonse Couturier. Cependant, Alphonse
                Couturier n'a pas la possibilité de vérifier le contenu des
                sites ainsi visités, et n'assumera en conséquence aucune
                responsabilité de ce fait. La navigation sur le site
                www.code-couturier.com est susceptible de provoquer
                l'installation de cookie(s) sur l'ordinateur de l'utilisateur.
                Un cookie est un fichier de petite taille, qui ne permet pas
                l'identification de l'utilisateur, mais qui enregistre des
                informations relatives à la navigation d'un ordinateur sur un
                site. Les données ainsi obtenues visent à faciliter la
                navigation ultérieure sur le site, et ont également vocation à
                permettre diverses mesures de fréquentation.
              </Card.Text>
            </Card>
          </Container>
        </Container>
      </div>
    );
  }
}

export default Mentionslegales;
