import "./Header1.scss";
import React, { useState } from "react";
import {
  FcHome,
  FcServices,
  FcCurrencyExchange,
  FcAbout,
  FcContacts,
} from "react-icons/fc";
import { NavLink } from "react-router-dom";
import { AiOutlineGithub, AiFillLinkedin } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  return (
    <>
      <nav className="main-nav">
        <div className="logo">
          <h2>
            <span>C</span>ode-<span>C</span>outurier
          </h2>
        </div>
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <NavLink className="navlink" to="../">
              <li>
                <FcHome className="item-icon" />
                <a href="/" onClick={() => setShowMediaIcons(!showMediaIcons)}>
                  Accueil
                </a>
              </li>
            </NavLink>
            <NavLink className="navlink" to="/services">
              <li>
                <FcServices className="item-icon" />
                <a
                  href="/services"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  Services
                </a>
              </li>
            </NavLink>
            <NavLink className="navlink" to="/tarifs">
              <li>
                <FcCurrencyExchange className="item-icon" />
                <a
                  href="/tarifs"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  Tarifs
                </a>
              </li>
            </NavLink>
            <NavLink className="navlink" to="/apropos">
              <li>
                <FcAbout className="item-icon" />
                <a
                  href="/apropos"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  A propos
                </a>
              </li>
            </NavLink>
            <NavLink className="navlink" to="/contact">
              <li>
                <FcContacts className="item-icon" />
                <a
                  href="/contact"
                  onClick={() => setShowMediaIcons(!showMediaIcons)}
                >
                  Contact
                </a>
              </li>
            </NavLink>
          </ul>
        </div>
        <div className="social-media">
          {" "}
          <ul className="social-media-desktop">
            <li>
              <a
                href="https://www.linkedin.com/in/alphonse-couturier/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillLinkedin className="linkedin" size="30" />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/AlphonseCou"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineGithub className="gitlab" size="30" />
              </a>
            </li>
          </ul>
          <div className="hamburger-menu">
            <GiHamburgerMenu
              onClick={() => setShowMediaIcons(!showMediaIcons)}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
