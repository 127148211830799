import "./CarouselType.scss";
import React from "react";
import { Carousel } from "react-bootstrap";

const CarouselHome = () => (
  <div className="carousel">
    <Carousel
      fade={true}
      className="slide"
      indicators={false}
      controls={false}
      prevLabel=""
      nextLabel=""
    >
      <Carousel.Item className="base-slide1" interval={2500}>
        <h1 className="titre-slide1">Alphonse Couturier</h1>
        <p className="resume-slide1">Développeur web fullstack freelance</p>
        <p className="resume-slide1">
          Je vous accompagne dans votre projet web
        </p>
        <Carousel.Caption className="slide"></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="base-slide2" interval={2500}>
        <h1 className="titre-slide2">Réaliser un projet complet</h1>
        <p className="resume-slide2">
          Un site vitrine, de e-commerce, un blog, ...
        </p>

        <Carousel.Caption className="slide"></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="base-slide3" interval={2500}>
        <h1 className="titre-slide3">Participer à un projet</h1>
        <p className="resume-slide3">
          Réalisation d'un élément, amélioration de code, mise à jour, conseil,
          ...
        </p>
        <Carousel.Caption className="slide"></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </div>
);

export default CarouselHome;
