import "./Footer.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { FcLibrary } from "react-icons/fc";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import copyright from "./../Images/copyright.png";

class footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="flex-footer">
          <OverlayTrigger
            overlay={<Tooltip className="tooltip">Mentions Légales</Tooltip>}
          >
            <span className="d-inline-block">
              <NavLink className="navlink" to="/mentionslegales">
                <FcLibrary className="cv" size="30" />
              </NavLink>
            </span>
          </OverlayTrigger>
        </div>
        <div className="div-copyright">
          <img src={copyright} className="copyright" alt="" />
          <p className="copyright-text">2021 Alphonse Couturier</p>
        </div>
      </footer>
    );
  }
}

export default footer;
