import "./../src/styles/app.scss";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Tarifs from "./Pages/Tarifs";
import Apropos from "./Pages/Apropos";
import Contact from "./Pages/Contact";
import Mentionslegales from "./Pages/Mentionslegales";
import Loader from "./components/Loader/Loader";
import Footer from "./components/Layout/Footer";
import Navbar from "./components/Layout/Header1";
import { Navigate } from "react-router-dom";

function App() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  }, []);

  return (
    /*  loader ? */ /* <Loader />
  ) : ( */
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/tarifs" element={<Tarifs />} />
          <Route path="/apropos" element={<Apropos />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mentionslegales" element={<Mentionslegales />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
