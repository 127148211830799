import "./Home.scss";
import React from "react";
import CarouselHome from "./../components/Carousel/CarouselType";

class Home extends React.Component {
  render() {
    return (
      <div className="App">
        <CarouselHome />
      </div>
    );
  }
}

export default Home;
