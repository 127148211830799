import "./Contact.scss";
import React from "react";
import { MdAlternateEmail } from "react-icons/md";
import { BsWhatsapp, BsSkype, BsDiscord, BsTelegram } from "react-icons/bs";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import Basic from "./../components/Basic/Basic";
import { SiMicrosoftteams, SiViber } from "react-icons/si";
import zoom from "./../components/Images/zoom.svg";
function Contact() {
  return (
    <div className="App">
      <h1 className="titre-page">Contactez moi :</h1>
      <h4 className="accroche-contact">
        Vous souhaitez réaliser un projet, développer une idée, vous lancer.
        N'hésitez pas à prendre contact pour en discuter et voir si l'on peut
        travailler ensemble. Cela ne coûte rien d'en échanger !
      </h4>
      <Container className="container-contact">
        <div className="formulaire">
          <Basic />
        </div>
        <div className="capsule-contact">
          <p className="texte-contact">
            Pour me joindre, privilégiez le formulaire sinon vous pouvez aussi
            utiliser les moyens suivants :
          </p>
          <br />
          <p className="moyens-contact">
            <a href="https://calendly.com/code-couturier">
              <Button className="RDV">
                {" "}
                Prenez rendez vous c'est gratuit ! Il suffit de cliquer ici
              </Button>
            </a>
            <br />
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Adresse mail</Tooltip>}
            >
              <span className="span-contact">
                <MdAlternateEmail className="picto" size="20" />
                e-mail: code.couturier@gmail.com
              </span>
            </OverlayTrigger>
            <br />
            <br />
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Whatsapp</Tooltip>}
            >
              <span className="span-contact">
                <BsWhatsapp color="#25d366" className="picto" size="18" />
                Via Whatsapp: 06.47.54.19.30
              </span>
            </OverlayTrigger>
            <br />
            <br />
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Skype</Tooltip>}
            >
              <span className="span-contact">
                <BsSkype color="#09ADEC" className="picto" size="21" />
                Pseudo: alphonsecouturier_1
              </span>
            </OverlayTrigger>
            <br />
            <br />
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Discord</Tooltip>}
            >
              <span className="span-contact">
                <BsDiscord color="#7289d9" className="picto" size="20" />
                Alphonse#9771
              </span>
            </OverlayTrigger>
          </p>
          <p className="texte-autre-contact">Et bien d'autres :</p>
          <p className="moyens-contact">
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Teams</Tooltip>}
            >
              <span className="span-contact">
                <SiMicrosoftteams color="#464EB8" className="picto" size="21" />
              </span>
            </OverlayTrigger>{" "}
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Telegram</Tooltip>}
            >
              <span className="span-contact">
                <BsTelegram color="#0088CC" className="picto" size="21" />
              </span>
            </OverlayTrigger>{" "}
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Viber</Tooltip>}
            >
              <span className="span-contact">
                <SiViber color="#665CAC" className="picto" size="21" />
              </span>
            </OverlayTrigger>{" "}
            <OverlayTrigger
              overlay={<Tooltip className="tooltip">Zoom</Tooltip>}
            >
              <span className="span-contact">
                <img src={zoom} className="zoom" alt="" />
              </span>
            </OverlayTrigger>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
