import "./Services.scss";
import React from "react";
import { Container } from "react-bootstrap";
import {
  FcSettings,
  FcAddressBook,
  FcSupport,
  FcMultipleDevices,
} from "react-icons/fc";

class Services extends React.Component {
  render() {
    return (
      <div className="App">
        <h1 className="titre-page">Services</h1>
        <h2 className="accroche-services">
          Liste non exhaustive, je m'adapte surtout à vos besoins et demandes{" "}
        </h2>
        <Container className="container-services">
          <div className="colonne-1-services">
            <div className="service">
              <FcSettings className="icon-service" />
              <div className="texte-service">
                <h4 className="titre-service">GESTION DE PROJETS WEB</h4>
                <p className="detail-service">
                  Site vitrine, corporate, évènementiel, e-commerce, intranet,
                  application mobile.
                </p>
              </div>
            </div>
            <div className="service">
              <FcAddressBook className="icon-service" />
              <div className="texte-service">
                <h4 className="titre-service">INTÉGRATION WEB</h4>
                <p className="detail-service">
                  Des intégrations respectueuses des standards du Web.
                </p>
              </div>
            </div>
          </div>
          <div className="colonne-2-services">
            <div className="service">
              <FcSupport className="icon-service" />
              <div className="texte-service">
                <h4 className="titre-service">DÉVELOPPEMENTS SPÉCIFIQUES</h4>
                <p className="detail-service">
                  Des outils adaptés à votre coeur de métier, applications &
                  solutions personnalisées.
                </p>
              </div>
            </div>
            <div className="service">
              <FcMultipleDevices className="icon-service" />
              <div className="texte-service">
                <h4 className="titre-service">RESPONSIVE DESIGN</h4>
                <p className="detail-service">
                  Compatible tous supports, tablette & application mobile.
                </p>
              </div>
            </div>
          </div>
        </Container>
        {/*         <Slider />
         */}{" "}
      </div>
    );
  }
}

export default Services;
