import "./Basic.scss";
import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button } from "react-bootstrap";
import { MdAlternateEmail } from "react-icons/md";
import { FcBusinessman, FcPhone, FcList } from "react-icons/fc";
/* import ReCAPTCHA from "react-google-invisible-recaptcha";
 */
const Basic = () => {
  return (
    <div className="basic">
      <Formik
        initialValues={{ name: "", email: "", phone: "", message: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Ne soyez pas timide";
          }
          if (!values.email) {
            errors.email =
              "Votre email est necessaire mais soyez serein je ne le partage avec personne !";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Attention adresse email invalide";
          }
          if (!values.message) {
            errors.message =
              "Si vous ne me dites rien cela va être dificile de cerner votre besoin !";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 400);

          window.emailjs
            .send(
              "code.couturier@gmail.com",
              process.env.REACT_APP_FORMIK_TEMPLATE_ID,
              values
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
                alert("Votre demande a bien été envoyée");
              },
              function (error) {
                console.log("FAILED...", error);
              }
            );
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FcBusinessman className="icon" size="18" />
            <Field
              type="name"
              name="name"
              className="input"
              placeholder="Prénom / Nom"
            />
            <ErrorMessage
              className="error-message"
              name="name"
              component="div"
            />
            <p></p>
            <MdAlternateEmail className="icon" size="18" />
            <Field
              type="email"
              name="email"
              className="input"
              placeholder="Votre e-mail"
            />
            <ErrorMessage
              className="error-message"
              name="email"
              component="div"
            />
            <p></p>
            <FcPhone className="icon" size="18" />
            <Field
              type="phone"
              name="phone"
              className="input"
              placeholder="Votre téléphone"
            />
            <ErrorMessage
              className="error-message"
              name="téléphone"
              component="div"
            />
            <p></p>
            <FcList className="icon" size="18" />
            <Field
              as="textarea"
              name="message"
              className="input-message"
              placeholder="Votre demande, projet, question ou autre souhait ?"
              rows="15"
            />
            <ErrorMessage
              className="error-message"
              name="message"
              component="div"
            />
            <p></p>
            <Button className="bouton" type="submit" disabled={isSubmitting}>
              Envoyer
            </Button>
            {/*             <ReCAPTCHA
              className="recaptcha"
              sitekey="6Leh8n8dAAAAAIXx-x51xFU4CXAUonlz0B9_5HNp"
              //onChange={onChange}
            /> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default Basic;
