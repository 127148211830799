import "./Apropos.scss";
import React from "react";
import { Container, ProgressBar } from "react-bootstrap";
import ENSEEIHT from "./../components/Images/ENSEEIHT.png";
import SAFTI from "./../components/Images/Safti.png";
import Map from "./../components/Map/Map";

class Apropos extends React.Component {
  render() {
    return (
      <div className="App">
        <h1 className="titre-page">A propos</h1>
        <h2 className="accroche-apropos">
          Ou suis je? Qui suis je? Que sais je?
        </h2>
        <Container className="container-apropos">
          <div className="localisation">
            <p className="texte-localisation">
              Je suis 'digital nomad', mes points de chutes les plus courants en
              ce moment sont Paris, Toulouse et Tunis.
            </p>

            <Map className="map" />
            <p className="definition">
              *Digital Nomad: "travailleur qui adopte un mode de vie dans lequel
              il voyage fréquemment tout en travaillant à distance, grâce à un
              métier qui nécessite uniquement un ordinateur et une bonne
              connexion Wifi."
            </p>
          </div>
          <div className="biographie">
            <p className="texte-bio">
              Durant 15 années j'ai fait du management de centres de profit.
              Puis j'ai découvert le monde du digital et plus particulièrement
              le métier de développeur pour lequel je me suis pris de passion.
            </p>
            <br />
            <p className="texte-bio">
              J'ai donc fait une reconversion professionnelle. J'ai acquis mon
              Diplome Universitaire de Développeur WEB Fullstack à l'ENSEEIHT à
              Toulouse.
            </p>
            <img src={ENSEEIHT} className="enseeiht" alt="" />
            <p className="texte-bio">
              Puis toujours à Toulouse, j'ai travaillé en tant que Développeur
              front-end chez Safti. Ensuite de pouvoir être un digital nomad,
              j'ai pris mon envol en devenant freelance. Mon but est donc de
              mettre mes compétences au service de votre projet, votre site.
            </p>
            <img src={SAFTI} className="safti" alt="" />
            <p className="texte-bio">
              Mon but aujourd'hui est donc de mettre mes compétences au service
              de votre projet, votre site. Toujours guidé par la satisfaction
              client, mon objectif est que votre rêve web devienne réalité.
            </p>
          </div>
          <div className="compétences">
            <h4>Compétences en développement</h4>
            <br />
            <p className="techno">
              React.js <ProgressBar className="level" striped now={80} />
            </p>
            <p className="techno">
              Vue.js <ProgressBar className="level" striped now={65} />
            </p>
            <p className="techno">
              Node.js <ProgressBar className="level" striped now={65} />
            </p>
            <p className="techno">
              Django <ProgressBar className="level" striped now={60} />
            </p>

            <p className="techno">
              Javascript <ProgressBar className="level" striped now={70} />
            </p>
            <p className="techno">
              Redux <ProgressBar className="level" striped now={70} />
            </p>
            <p className="techno">
              (X)HTML - CSS
              <ProgressBar className="level" striped now={70} />
            </p>
            <p className="techno">
              Typescript <ProgressBar className="level" striped now={60} />
            </p>
            <p className="techno">
              Docker <ProgressBar className="level" striped now={50} />
            </p>
            <p className="autres-technos">
              Cette liste est partielle, et mon apprentissage est constant et
              permanent.
            </p>
          </div>
          {/*           <img src={carte} className="planisphere" alt="" />
           */}{" "}
          {/*             <p className="texte">
              En définitive je suis donc quelque part dans le monde mais cela
              change souvent ! Je suis bien réel et professionnel, voici même
              mon numéro de SIREN : 903 761 096
            </p> */}
        </Container>
      </div>
    );
  }
}

export default Apropos;
