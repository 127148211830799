import "./Tarifs.scss";
import React from "react";
import { Button, Container, Card } from "react-bootstrap";

class Tarifs extends React.Component {
  render() {
    return (
      <div className="App">
        <h1 className="titre-page">Tarifs</h1>
        <h2 className="accroche-tarifs">
          Différentes prestations, à titre indicatif, l'idéal est d'en discuter.
        </h2>
        <Container className="container-tarifs">
          <div className="colonne-tarifs">
            <Card className="card-tarif">
              <Card.Header className="card-header-tarif">
                Site vitrine 'classique'
              </Card.Header>
              <Card.Text className="card-text-tarif">
                <ul className="ul-tarif">
                  <li className="li-tarif">Développé sous WORDPRESS (CMS).</li>
                  <li className="li-tarif">Charte graphique pré-définie.</li>
                  <li className="li-tarif">
                    Domaine + hébergement + e-mail pendant 12mois.
                  </li>
                  <li className="li-tarif">
                    Interface administrateur STANDARD.{" "}
                  </li>
                  <li className="li-tarif">
                    Site responsive (adapté tous supports).{" "}
                  </li>
                </ul>
                <ul className="ul-tarif">
                  <li className="li-tarif">
                    Mise en place du contenu (textes & images).{" "}
                  </li>
                  <li className="li-tarif">Formulaire de contact. </li>
                  <li className="li-tarif">
                    Liens de partage sur les réseaux sociaux.{" "}
                  </li>
                  <li className="li-tarif">Mise en ligne du site.</li>
                  <li className="li-tarif">
                    Accompagnement utilisation du site.
                  </li>
                </ul>
              </Card.Text>
              <Card.Footer className="card-footer-tarif">
                A partir de: 1000€
              </Card.Footer>
            </Card>
            <br />
            <br />
            <br />
            <Card className="card-tarif">
              <Card.Header className="card-header-tarif">
                Site vitrine 'personnalisé'
              </Card.Header>
              <Card.Text className="card-text-tarif">
                <ul className="ul-tarif">
                  <li className="li-tarif">
                    Développement personnalisé (codage à la main).
                  </li>
                  <li className="li-tarif">
                    Charte graphique et palette de couleurs personnalisées.
                  </li>
                  <li className="li-tarif">
                    Domaine + hébergement + e-mail pendant 12mois.
                  </li>
                  <li className="li-tarif">
                    Interface administrateur personnalisé.{" "}
                  </li>
                  <li className="li-tarif">
                    Site responsive (adapté tous supports).{" "}
                  </li>
                </ul>
                <ul className="ul-tarif">
                  <li className="li-tarif">
                    Mise en place du contenu (textes & images).{" "}
                  </li>
                  <li className="li-tarif">Formulaire de contact. </li>
                  <li className="li-tarif">
                    Liens de partage sur les réseaux sociaux.{" "}
                  </li>
                  <li className="li-tarif">Mise en ligne du site.</li>
                  <li className="li-tarif">
                    Accompagnement utilisation du site.
                  </li>
                </ul>
              </Card.Text>
              <Card.Footer className="card-footer-tarif">
                A partir de: 1 500€
              </Card.Footer>
            </Card>
          </div>
          <div className="colonne-tarifs">
            <Card className="card-tarif">
              <Card.Header className="card-header-tarif">
                Site e-commerce (plateforme)
              </Card.Header>
              <Card.Text className="card-text-tarif">
                <ul className="ul-tarif">
                  <li className="li-tarif">WORDPRESS ou PRESTASHOP (CMS).</li>
                  <li className="li-tarif">
                    Charte graphique et palette de couleurs personnalisées.
                  </li>
                  <li className="li-tarif">
                    Domaine + hébergement + e-mail pendant 12mois.
                  </li>
                  <li className="li-tarif">
                    Ajout et configuration des pluggins souhaités (Options).{" "}
                  </li>
                  <li className="li-tarif">
                    Site responsive (adapté tous supports).{" "}
                  </li>
                </ul>
                <ul className="ul-tarif">
                  <li className="li-tarif">
                    Mise en place du contenu (textes & images){" "}
                  </li>
                  <li className="li-tarif">Formulaire de contact. </li>
                  <li className="li-tarif">
                    Liens de partage sur les réseaux sociaux.{" "}
                  </li>
                  <li className="li-tarif">Mise en ligne du site.</li>
                  <li className="li-tarif">
                    Accompagnement utilisation du site.
                  </li>
                </ul>
              </Card.Text>
              <Card.Footer className="card-footer-tarif">
                A partir de: 2 000€
              </Card.Footer>
            </Card>
            <br />
            <Card className="card-tarif-spe">
              <Card.Header className="card-header-tarif-spe">
                Maintenance
              </Card.Header>
              <Card.Text className="card-text-tarif-spe"></Card.Text>
              <Card.Footer className="card-footer-tarif-spe">
                En moyenne: 70€/Heure
              </Card.Footer>
            </Card>
            <Card className="card-tarif-spe">
              <Card.Header className="card-header-tarif-spe">
                Développements particuliers
              </Card.Header>
              <Card.Text className="card-text-tarif-spe"></Card.Text>
              <Card.Footer className="card-footer-tarif-spe">
                En moyenne: 500€/Jour
              </Card.Footer>
            </Card>
            <Card className="card-exemples">
              <Card.Header className="card-header-exemples">
                Voici quelques réalisations:
              </Card.Header>
              <Card.Text className="card-text-exemples">
                <a
                  className="lien-exemples"
                  href="https://www.ip-label.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Site vitrine pour une entreprise réalisé en WordPress
                </a>
                <br></br>
                <a
                  className="lien-exemples"
                  href="http://never-shop.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Site vitrine et commerçant simple pour un Street artiste
                  réalisé en WordPress
                </a>
              </Card.Text>
              <Card.Footer className="card-footer-exemples">
                D'autres projets en cours à découvrir bientôt
              </Card.Footer>
            </Card>
          </div>
        </Container>
        <a href="https://calendly.com/code-couturier">
          <Button className="RDVTarif">
            {" "}
            Discutons ensemble, c'est plus simple. Il suffit de cliquer ici
            c'est gratuit !
          </Button>
        </a>
      </div>
    );
  }
}

export default Tarifs;
