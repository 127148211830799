import "./Map.scss";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import tunisie from "./../Images/tunisie.png";
import france from "./../Images/france.png";

class Map extends React.Component {
  render() {
    return (
      <MapContainer
        className="map"
        center={[43.604652, 1.444209]}
        zoom={3}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYWxwaG9uc2V6IiwiYSI6ImNrd2dnanJqMzBjOTAydW1seTNrd3E4MTMifQ.ezVFAm59TN6jcfhC13EkRA"
        />
        <Marker position={[43.604652, 1.444209]}>
          <Popup className="popup">
            <img src={france} className="drapeau" alt="" /> <br />
            France <br /> Toulouse
          </Popup>
        </Marker>
        <Marker position={[36.81897, 10.16579]}>
          <Popup className="popup">
            <img src={tunisie} className="drapeau" alt="" /> <br /> Tunis <br />
            Tunisie
          </Popup>
        </Marker>
        <Marker position={[48.856614, 2.3522219]}>
          <Popup className="popup">
            <img src={france} className="drapeau" alt="" /> <br />
            France <br /> Paris
          </Popup>
        </Marker>
      </MapContainer>
    );
  }
}
export default Map;
